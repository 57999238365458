
import { defineComponent, ref, onMounted } from "vue";
import KTDatatable from "@/components/kt-datatable/KTDatatable.vue";
import KTNewDeviceModal from "@/components/modals/forms/NewDeviceModal.vue";
import DeviceUpdatemodal from "./modals/DeviceUpdatemodal.vue";
import DeleteDevicemodal from "./modals/DeleteDevicemodal.vue";
import ViewDeviceModal from "./modals/ViewDeviceModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ExcelJS from 'exceljs';
import moment from "moment";

import {
  getCustomersList,
  getDevicesListing,
  getDevicesExport,
  deleteDeviceData,
  deleteDeviceFromAdmin,
  deleteDeviceFromPlatform,
} from "@/store/api/devices";
interface IPagination {
  page_number: number;
  page_size: number;
  customer_id: string;
  filters: {
    name: string;
  };
}
interface ExportFilter {
  customer_id: string;
  filters: {
    name: string;
  };
}
export default defineComponent({
  name: "repository",
  components: {
    KTDatatable,
    KTNewDeviceModal,
    DeviceUpdatemodal,
    DeleteDevicemodal,
    ViewDeviceModal
  },
  setup() {
    const d_varinat = ref<string>("");
    const data = ref<any[]>([]);
    const expoData = ref<any[]>([]);
    const deviceData = ref<any[]>([]);
    const customers = ref<any[]>([]);
    const final_array = ref<any[]>([]);
    const loading = ref<boolean>(true);
    const search = ref<string>("");
    const cardData = ref<string>("");
    const variantId = ref<any[]>([]);
    const checkedValue = ref<any[]>([]);
    const checked = ref<boolean>(false);
      
    const headerConfig = ref([
      {
        key: "checkbox",
        sortable: false,
      },
      {
        name: "Variant Id",
        key: "variant_id",
      },
      {
        name: "Variant",
        key: "variant",
      },
      {
        name: "Customer",
        key: "name",
      },
      {
        name: "api doc version",
        key: "api_doc_version",
      },
      {
        name: "firmware version",
        key: "firmware_version",
      },
      {
        name: "Action",
        key: "action",
      },
    ]);

    const permission = ref<any>("");

    const total = ref<number>(0);
    const pagination = ref<IPagination>({
      page_number: 1,
      customer_id: "",
      page_size: 10,
      filters: {
        name: "",
      },
    });
    const exportFilter = ref<ExportFilter>({
      customer_id: "",
      filters: {
        name: "",
      },
    });
    //Received success response from child compnent then delete device
    const parentfunc = (va) => {
      if (va === true) {
        deleteDeviceData(d_varinat.value).then((res) => {
          loadData();
        });
      }
    };
    
    onMounted(async () => {
      loading.value = true;
      getCustomersList().then((response) => {
        customers.value = response;
        loadData();
      });
      permission.value = localStorage.getItem("permissions");
      permission.value = JSON.parse(permission.value);
      setCurrentPageBreadcrumbs("Devices", [""]);
    });

    const loadData = async () => {
      loading.value = true;
      let res = await getDevicesListing(pagination.value);
      data.value = res.devices;
      total.value = res.total_number_of_records;
      loading.value = false;
    };

    const excelExport = async () => {
      try {
        const expo = await getDevicesExport(exportFilter.value);
        expoData.value = expo.devices;
        final_array.value = [];
        // Create a new Excel workbook and worksheet
        if(expoData.value.length > 0) {
          expoData.value.map(i => {
            const obj = {
              UniqueRandomNumber: i.unique_random_number,
              Hardware: i.hardware,
              SoftwareVersion: '('+i.software_version+'): '+ i.software_version_detail,
              Variant: i.variant,
              VariantId: i.variant_id,
              SerialNumber: i.serial_number,
              ODM: '('+i.odm+'): ' + i.odm_detail,
              AssemblyLineBatchNumber: i.assembly_line_batch_number,
              Date: moment(i.date).format('DD MMM YYYY'),
              ProductCountOnAssemblyLine: i.product_count_on_assembly_line,
              ApplicationEUI: i.application_eui,
              ApplicationKey: i.application_key,
              Icc: i.icc,
              Antenna: '('+i.antenna+'): '+ i.antenna_detail,
              Battery: '('+i.battery+'): '+ i.battery_detail,
              Gasket: '('+i.gasket+'): '+ i.gasket_detail,
              SealantOnTunnel: '('+i.sealant_on_tunnel+'): '+ i.sealant_on_tunnel_detail,
              Sensor: '('+i.sensor+'): '+ i.sensor_detail,
              CasingBatchNumber: '('+i.casing_batch_number+'): '+ i.casing_batch_number_detail,
              Screws: '('+i.screws+'): '+ i.screws_detail,
              ConformalCoating: '('+i.conformal_coating+'): '+ i.conformal_coating_detail,
              Silicon: '('+i.silicon_between_lid_and_housing+'): '+ i.silicon_detail,
              ProductId: i.product_id,
              LNS: i.lns,
              NetworkConnectivity: i.network_connectivity,
              Protocol: i.protocol,
              ShipmentDate: moment(i.shipment_date).format('DD MMM YYYY'),
              WarrantyDate: moment(i.warranty_date).format('DD MMM YYYY'),
              LastSeenDate: moment(i.last_seen_date).format('DD MMM YYYY'),
              CustomerId: i.customer_id,
              CustomerName: i.customer_name
            };

            final_array.value.push(obj);
          });

          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet('Devices');
          const columns = [
            { header: 'Device UID', key: 'UniqueRandomNumber', width: 20 },
            { header: 'Variant ID', key: 'VariantId', width: 20 },
            { header: 'Variant', key: 'Variant', width: 10 },
            { header: 'Application EUI', key: 'ApplicationEUI', width: 20 },
            { header: 'Application Key', key: 'ApplicationKey', width: 40 },
            { header: 'ICCID', key: 'Icc', width: 30 },
            { header: 'Serial Number', key: 'SerialNumber', width: 20 },
            { header: 'Hardware', key: 'Hardware', width: 20 },
            { header: 'Firmware Version', key: 'SoftwareVersion', width: 30 },
            { header: 'ODM', key: 'ODM', width: 30 },
            { header: 'Assembly Line Batch Number', key: 'AssemblyLineBatchNumber', width: 30 },
            { header: 'Date From Serial ID', key: 'Date', width: 30 },
            { header: 'Product Count on Assembly Line', key: 'ProductCountOnAssemblyLine', width: 20 },
            { header: 'Antenna', key: 'Antenna', width: 30 },
            { header: 'Battery', key: 'Battery', width: 30 },
            { header: 'Gasket', key: 'Gasket', width: 40 },
            { header: 'Sealant', key: 'SealantOnTunnel', width: 40 },
            { header: 'Sensor', key: 'Sensor', width: 40 },
            { header: 'Casing', key: 'CasingBatchNumber', width: 40 },
            { header: 'Screws', key: 'Screws', width: 40 },
            { header: 'Conformal Coating', key: 'ConformalCoating', width: 40 },
            { header: 'Silicon', key: 'Silicon', width: 40 },
            { header: 'Product ID', key: 'ProductId', width: 20 },
            { header: 'LNS', key: 'LNS', width: 20 },
            { header: 'Network Connectivity', key: 'NetworkConnectivity', width: 20 },
            { header: 'Protocol', key: 'Protocol', width: 20 },
            { header: 'Shipment Date', key: 'ShipmentDate', width: 20 },
            { header: 'warranty_date', key: 'WarrantyDate', width: 20 },
            { header: 'Last Seen Date', key: 'LastSeenDate', width: 20 },
            { header: 'Customer ID', key: 'CustomerId', width: 10 },
            { header: 'Customer Name', key: 'CustomerName', width: 30 },
            // Add more columns as needed
          ];
          // Set column headers in the worksheet
          worksheet.columns = columns;

          // Add data rows to the worksheet
          final_array.value.forEach(device => {
            worksheet.addRow(device);
          });

          // Set up a download link for the Excel file
          const blob = await workbook.xlsx.writeBuffer();
          const blobUrl = URL.createObjectURL(new Blob([blob]));
          const a = document.createElement('a');
          a.href = blobUrl;
          a.download = 'devices.xlsx';
          a.click();
        }
        // Define column headers
      } catch (error) {
        console.error('Error exporting data:', error);
      }
    }
    
    const editdeviceData = (val) => {
      deviceData.value = val;
    };
    const deleteDevicedata = (val) => {
      d_varinat.value = val;
    };
    const currentChange = (val) => {
      pagination.value.page_number = val;
      loadData();
    };

    const onCustomerChange = (event) => {
      pagination.value.page_number = 1;
      pagination.value.customer_id = event.target.value;
      exportFilter.value.customer_id = event.target.value;
      loadData();
    };

    const getCustomerName = (val) => {
      let name = customers.value.filter((x) => (x.id = val))[0].name;
      return name;
    };

    const searchItems = () => {
      pagination.value.page_number = 1;
      if (search.value !== "") {
        pagination.value.filters.name = search.value;
        exportFilter.value.filters.name = search.value;        
      } else {
        pagination.value.filters.name = "";
        exportFilter.value.filters.name = "";
        expoData.value = [];
      }
      loadData();
    };

    const selectAll = (val) => {
      if (val === true) {
        data.value.forEach((x) => {
          checkedValue.value.push(x);
        });
      } else {
        checkedValue.value = checkedValue.value.filter(
          (d) => !data.value.find((rm) => rm.variant_id === d.variant_id)
        );
      }
    };
    const isChecked = (val) =>
      checkedValue.value.find((element) => {
        if (element.variant_id === val) {
          return true;
        } else false;
      });

    const unCheck = (val) => {
      checkedValue.value.splice(
        checkedValue.value.findIndex((a) => a.variant_id === val),
        1
      );
    };
    const deleteDevices = () => {
      deleteDeviceFromAdmin(checkedValue.value.map((c) => c.variant_id)).then(
        (res) => {
          loadData();
        }
      );
    };

    const selectDevice = (val) => {
      if (!isChecked(val.variant_id)) {
        checkedValue.value.push(val);
      } else {
        unCheck(val.variant_id);
      }
    };
    const itemsPerPageChange = (val) => {
      pagination.value.page_size = val;
      loadData();
    };
    const deleteDevice = async (val) => {
      Swal.fire({
        title: "Are you sure you want to delete?",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          const resp = await deleteDeviceFromPlatform(val);
          loadData();
        }
      });
    };
    return {
      data,
      headerConfig,
      customers,
      total,
      loading,
      search,
      deleteDevicedata,
      d_varinat,
      editdeviceData,
      parentfunc,
      deviceData,
      pagination,
      variantId,
      isChecked,
      selectDevice,
      deleteDevices,
      checkedValue,
      checked,
      selectAll,
      cardData,
      currentChange,
      searchItems,
      getCustomerName,
      itemsPerPageChange,
      onCustomerChange,
      deleteDevice,
      permission,
      expoData,
      excelExport
    };
  },
});
