
import { defineComponent, ref, watch } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { Field, Form, ErrorMessage } from "vee-validate";
import { checkPassword } from "@/store/api/devices";
export default defineComponent({
  name: "deletedevicemodal",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  emit: ["pra"],
  props: {
    variantId: { type: String, required: true },
  },
  setup(props, { emit }) {
    const device_variant = ref<any>([]);
    const createAPIKeyModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const user_auth = ref({
      password: "",
    });
    watch(
      () => props.variantId,
      (v) => {
        device_variant.value = v;
      }
    );
    const submit = () => {
      loading.value = !false;
      setTimeout(() => {
        checkPassword(user_auth.value).then((res) => {
          if (res.data.success === true) {
            emit("pra", res.data.success);
            document.getElementById("deletedevicemodal")?.click();
          } else {
            alert("Invalid Credentials");
          }
        });
        loading.value = !true;
      }, 2000);
    };
    return {
      device_variant,
      user_auth,
      submit,
      loading,
      createAPIKeyModalRef,
    };
  },
});
