
import { defineComponent, ref, watch } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { updateDeviceData } from "@/store/api/devices";
import { Field } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import moment from "moment";

export default defineComponent({
  name: "create-api-key-modal",
  components: {
    Field,
  },
  props: {
    varientData: { type: Object, required: true },
  },
  
  setup(props) {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const modalRef = ref<null | HTMLElement>(null);
    const createAPIKeyModalRef = ref<null | HTMLElement>(null);
    const serialDetailModalRef = ref<null | HTMLElement>(null);
    const payload = ref<any>([]);
    const toggle = ref(false);

    watch(
      () => props.varientData,
      (v) => {
        payload.value = v;
      }
    );
     
    const toggleSerialDetailModal = () => {
      if (serialDetailModalRef.value) {
        serialDetailModalRef.value.classList.toggle("show");
        const backdrop = document.querySelector(".modal-backdrop");
        if (backdrop) {
          backdrop.classList.toggle("show");
        }
      }
    };


    
    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      updateDeviceData(payload.value.device_id, payload.value).then((res) => {
        setTimeout(() => {
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;

            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
          Swal.fire({
            text: `${res.data.message}`,
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          }).then(() => {
            hideModal(createAPIKeyModalRef.value);
          });
        }, 2000);
      });
    };

    return {
      submit,
      payload,
      submitButtonRef,
      modalRef,  
      toggle,
      toggleSerialDetailModal,
      createAPIKeyModalRef,
      serialDetailModalRef,
      moment
    };
  },
});
